import React from "react";
import { Route, Switch } from "react-router-dom";
import './App.css';
import particlesOptions from "./particles.json";
import { Particles } from "react-tsparticles";

import Home from "./Home";
import AboutMe from "./AboutMe";
import SkillsProjects from "./SkillsProjects"
import Contact from "./Contact";

const App = () => {

  return (
    
    <div className="App">

        <Particles className="particles" options={particlesOptions} />
        
        <Switch>
          <Route exact path="/about">
            <AboutMe />
        </Route>
        <Route exact path="/skills">
          <SkillsProjects />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
      
    </div>
  )
}

export default App;
