import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typewriter from "typewriter-effect";
import registerIcons from "./FontAwesome";

registerIcons();

const SkillsProjects = () => {
  return (
    <div className="App-header">
      <div style={{ marginTop: "5%" }}>
        <a href="/" target="_self" rel="noopener noreferrer" id="nav-buttons">
          <span className="button">
            <FontAwesomeIcon
              className="icon"
              icon={["fas", "home"]}
              size="1x"
            />
            <span className="icon_title">Home</span>
          </span>
        </a>

        <a
          href="/contact"
          target="_self"
          rel="noopener noreferrer"
          id="nav-buttons"
        >
          <span className="button">
            <FontAwesomeIcon
              className="icon"
              icon={["fas", "paper-plane"]}
              size="1x"
            />
            <span className="icon_title">Contact</span>
          </span>
        </a>

        <a
          href="/about"
          target="_self"
          rel="noopener noreferrer"
          id="nav-buttons"
        >
          <span className="button">
            <FontAwesomeIcon
              className="icon"
              icon={["fas", "id-card"]}
              size="1x"
            />
            <span className="icon_title">About</span>
          </span>
        </a>
      </div>

      <h1 id="contact-type">
        <Typewriter
          options={{
            autoStart: true,
          }}
          onInit={(typewriter) => {
            typewriter.typeString("Skills && Projects").pauseFor(300).start();
          }}
        />
      </h1>

      <div>
        <a rel="noopener noreferrer" className="skill-buttons">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fab", "js-square"]}
              size="2x"
            />
            <span className="icon_title">JavaScript</span>
          </span>
        </a>
        <a rel="noopener noreferrer" className="skill-buttons">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fab", "react"]}
              size="2x"
            />
            <span className="icon_title">React</span>
          </span>
        </a>
        <a rel="noopener noreferrer">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fab", "html5"]}
              size="2x"
            />
            <span className="icon_title">HTML</span>
          </span>
        </a>
        <a rel="noopener noreferrer">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fab", "css3-alt"]}
              size="2x"
            />
            <span className="icon_title">CSS</span>
          </span>
        </a>
        <a rel="noopener noreferrer">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon className="icon" icon={["fas", "gem"]} size="2x" />
            <span className="icon_title">Ruby</span>
          </span>
        </a>
        <a rel="noopener noreferrer" className="skill-buttons">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fab", "python"]}
              size="2x"
            />
            <span className="icon_title">Python</span>
          </span>
        </a>
        <a rel="noopener noreferrer" className="skill-buttons">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fab", "java"]}
              size="2x"
            />
            <span className="icon_title">Java</span>
          </span>
        </a>
        <a rel="noopener noreferrer">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fas", "database"]}
              size="2x"
            />
            <span className="icon_title">SQL</span>
          </span>
        </a>
        <a rel="noopener noreferrer">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fas", "cogs"]}
              size="2x"
            />
            <span className="icon_title">Ruby On Rails</span>
          </span>
        </a>
        <a rel="noopener noreferrer">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fab", "microsoft"]}
              size="2x"
            />
            <span className="icon_title">Microsoft Office</span>
          </span>
        </a>
        <a rel="noopener noreferrer">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fas", "draw-polygon"]}
              size="2x"
            />
            <span className="icon_title">Adobe Suite (PS/AI)</span>
          </span>
        </a>
        <a rel="noopener noreferrer">
          <span className="button" id="skills-button-color">
            <FontAwesomeIcon className="icon" icon={["fab", "git"]} size="2x" />
            <span className="icon_title">Git</span>
          </span>
        </a>
      </div>

      <div>
        <a
          href="https://github.com/yuyi365/"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-buttons"
        >
          <span className="button" id="button-color">
            <FontAwesomeIcon
              className="icon"
              icon={["fab", "github"]}
              size="4x"
            />
            <span className="icon_title">Github</span>
          </span>
        </a>
      </div>

      <div style={{ padding: "3%", marginBottom: "3%" }}>
        <h2 style={{ color: "" }}>Project Demos... </h2>
        <span>
          <br></br>
          <h2>Tic-Tac-Toe</h2>
          <a
            href="https://github.com/yuyi365/tic_tac_toe"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Github</h3>
          </a>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/fltxvfWhVLM?start=2"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </span>

        <br></br>
        <span>
          <br></br>
          <h2>Explor - Discover Places</h2>
          <a
            href="https://github.com/yuyi365/explor_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Github</h3>
          </a>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/C4KSCac40nU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </span>

        <br></br>
        <span>
          <br></br>
          <h2>CountScores - Game Score Tracking Tool</h2>
          <a
            href="https://github.com/theweswing/count_scores_production"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Github</h3>
          </a>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/HjoQDjhKcBY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </span>

        <br></br>
        <span>
          <br></br>
          <h2>Trackr - Inventory Management Tool</h2>
          <a
            href="https://github.com/yuyi365/trackr_phasethreeproject"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Github</h3>
          </a>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/rlEek0tPuEw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </span>

        <br></br>
        <span>
          <br></br>
          <h2>Cocktail Recipe Finder</h2>
          <a
            href="https://github.com/yuyi365/phaseoneproject"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Github</h3>
          </a>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/c6glHsP0wd8?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </span>

        <br></br>
        <span>
          <br></br>
          <h2>Your Perfect Night In</h2>
          <a
            href="https://github.com/yuyi365/phasetwoproject"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Github</h3>
          </a>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/2E5m8vcOs64?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </span>

        <br></br>
        <span>
          <br></br>
          <h2>Book Explorer</h2>
          <a
            href="https://github.com/yuyi365/googlebooks_project"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Github</h3>
          </a>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/F6kXBZAY3-s"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </span>
      </div>
    </div>
  );
};

export default SkillsProjects;
