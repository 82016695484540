import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import registerIcons from "./FontAwesome";

registerIcons();

const Buttons = () => {
  return (
    <div>
        <a href="/skills" target="_self" rel="noopener noreferrer" className="contact-buttons"> 
        <span className="button" id="button-color">
            <FontAwesomeIcon className="icon" icon={["fas", "code"]} size="3x" />
            <span className="icon_title">Skills and Projects</span>
        </span>
        </a>

        <a href="/about" target="_self" rel="noopener noreferrer" className="contact-buttons"> 
        <span className="button" id="button-color">
            <FontAwesomeIcon className="icon" icon={["fas", "id-card"]} size="3x" />
            <span className="icon_title">About</span>
        </span>
        </a>

        <a href="/contact" target="_self" rel="noopener noreferrer" className="contact-buttons"> 
        <span className="button" id="button-color">
            <FontAwesomeIcon className="icon" icon={["fas", "paper-plane"]} size="3x" />
            <span className="icon_title">Contact</span>
        </span>
        </a>

    </div>  
  )
}

export default Buttons;
