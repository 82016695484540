// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import individual icons
import {
  faGithub,
  faLinkedin,
  faJsSquare,
  faReact,
  faHtml5,
  faCss3Alt,
  faMicrosoft,
  faGit,
  faJava,
  faPython,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPaperPlane,
  faIdCard,
  faCode,
  faHome,
  faGem,
  faDatabase,
  faCogs,
  faDrawPolygon,
  faBlog,
} from "@fortawesome/free-solid-svg-icons";

export default function registerIcons() {
  library.add(
    faGithub,
    faLinkedin,
    faPaperPlane,
    faIdCard,
    faCode,
    faHome,
    faJsSquare,
    faReact,
    faGem,
    faHtml5,
    faCss3Alt,
    faDatabase,
    faMicrosoft,
    faCogs,
    faDrawPolygon,
    faBlog,
    faGit,
    faJava,
    faPython
  );
}
