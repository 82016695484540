import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typewriter from "typewriter-effect";

const AboutMe = () => {
  return (
    <div className="App-header">
      <h1 id="about-type">
        <Typewriter
          options={{
            autoStart: true,
          }}
          onInit={(typewriter) => {
            typewriter.typeString("About Yuyi").pauseFor(300).start();
          }}
        />
      </h1>

      <div id="bio-block">
        <h2 className="bio">
          Yuyi is a developer with over 5 years of strategic brand building
          experience at consumer companies.
        </h2>

        <h2 className="bio">Current location: New York City 🗽 </h2>
        <div>
          <h3>
            Born in Chengdu 🇨🇳
            <br></br> Lived in Singapore 🇸🇬
            <br></br> Grew up in North Carolina 🇺🇸
          </h3>
        </div>

        <h2 className="bio"> Education </h2>
        <div>
          <h3>
            Flatiron School ('22) 💻 Full Stack Software Engineering
            <br></br> Duke University ('17) 🎓 Psychology [minors: Art History,
            English]
          </h3>
        </div>

        <h2 className="bio" id="bio-activity">
          Activities and Interests...
        </h2>
        <div>
          <h3>
            Explore museums and galleries 🖼️
            <br></br> Discover interesting restaurants 🥘
            <br></br> Create art - mixed media 🎨 / photography 📷
          </h3>
        </div>

        <div style={{ marginTop: "2%" }}>
          <a href="/" target="_self" rel="noopener noreferrer" id="nav-buttons">
            <span className="button">
              <FontAwesomeIcon
                className="icon"
                icon={["fas", "home"]}
                size="1x"
              />
              <span className="icon_title">Home</span>
            </span>
          </a>

          <a
            href="/skills"
            target="_self"
            rel="noopener noreferrer"
            id="nav-buttons"
          >
            <span className="button">
              <FontAwesomeIcon
                className="icon"
                icon={["fas", "code"]}
                size="1x"
              />
              <span className="icon_title">Skills and Projects</span>
            </span>
          </a>

          <a
            href="/contact"
            target="_self"
            rel="noopener noreferrer"
            id="nav-buttons"
          >
            <span className="button">
              <FontAwesomeIcon
                className="icon"
                icon={["fas", "paper-plane"]}
                size="1x"
              />
              <span className="icon_title">Contact</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
