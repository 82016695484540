import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typewriter from 'typewriter-effect'

const Contact = () => {

    return (

    <div className="App-header-two">

        <div>
            <a href="/" target="_self" rel="noopener noreferrer" id="nav-buttons"> 
            <span className="button">
                <FontAwesomeIcon className="icon" icon={["fas", "home"]} size="1x" />
                <span className="icon_title">Home</span>
            </span>
            </a>

            <a href="/skills" target="_self" rel="noopener noreferrer" id="nav-buttons"> 
            <span className="button">
                <FontAwesomeIcon className="icon" icon={["fas", "code"]} size="1x" />
                <span className="icon_title">Skills and Projects</span>
            </span>
            </a>

            <a href="/about" target="_self" rel="noopener noreferrer" id="nav-buttons"> 
            <span className="button">
                <FontAwesomeIcon className="icon" icon={["fas", "id-card"]} size="1x" />
                <span className="icon_title">About</span>
            </span>
            </a>

        </div> 
    
        <h1 id="contact-type">
                <Typewriter
                    options={{
                    autoStart: true,
                    }}
                    onInit={(typewriter) => {
                    typewriter
                    .typeString('Contact')
                    .pauseFor(300)
                    .start()
                }}
                />
        </h1>

        <div>

            <a href="https://www.linkedin.com/in/yuyili/" target="_blank" rel="noopener noreferrer" className="contact-buttons"> 
            <span className="button" id="button-color">
                <FontAwesomeIcon className="icon" icon={["fab", "linkedin"]} size="3x" />
                <span className="icon_title">LinkedIn</span>
            </span>
            </a>

            <a href="mailto:yuyi365@gmail.com" target="_blank" rel="noopener noreferrer" className="contact-buttons"> 
            <span className="button" id="button-color">
                <FontAwesomeIcon className="icon" icon={["fas", "paper-plane"]} size="3x" />
                <span className="icon_title">Email</span>
            </span>
            </a>

            <a href="https://medium.com/@yuyi365" target="_blank" rel="noopener noreferrer" className="contact-buttons"> 
            <span className="button" id="button-color">
                <FontAwesomeIcon className="icon" icon={["fas", "blog"]} size="3x" />
                <span className="icon_title">Blog</span>
            </span>
            </a>
        </div> 

    </div>
    )
}

  export default Contact;
