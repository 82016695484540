import Typewriter from "typewriter-effect";
import Buttons from "./Buttons";

const Home = () => {
  return (
    <div className="App-header-two">
      <h1 id="intro"> Yuyi Li 👩🏻 </h1>

      <span id="type">
        <Typewriter
          options={{
            autoStart: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString("software engineer")
              .pauseFor(200)
              .typeString(" && brand developer")
              .pauseFor(200)
              .typeString(" || always learning")
              .start();
          }}
        />
      </span>

      <span>
        <Buttons />
      </span>
    </div>
  );
};

export default Home;
